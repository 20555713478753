export const EMAIL_REGXP = /^([\w+-.+]+@([\w-]+\.)+[\w-]{2,4})?$/;
export const PERSONAL_EMAIL_REGXP = /^([\w-.+]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!outlook.com)(?!zoho.com)(?!aol.com)(?!mail.com)(?!yandex.com)(?!yandex.com)(?!inbox.com)(?!gmx.com)(?!icloud.com)(?!aim.com)(?!qq.com)([\w-]+\.)+[\w-]{2,4})?$/;
export const URL_REGXP = new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*(\\?[^]*)?(\\#[-a-z\\d_]*)?$', 'i');
export const PHONE_REGXP = new RegExp('^((\\+[1-9]{1,4}[ \\- ]*)|(\\([0-9]{2,3}\\)[ \\- ]*)|([0-9]{2,4})[ \\- ]*)*?[0-9]{3,4}?[ \\- ]*[0-9]{3,4}?$');
export const IMAGE_URL_REGXP = new RegExp('^http[s]?:([^(["<,>/]*)()[^[",><]*.(png|jpg|jpeg)([^[",><]*)?');
export const RAKUNA_COLOR = '#f16567';
export const HOVER_RAKUNA_COLOR = '#e21313';
export const HOVER_PRIMARY_COLOR = '#f17979';
export const SECONDARY_COLOR = '#89cff0';
export const HOVER_SECONDARY_COLOR = '#4682b4';
export const SECONDARY_BLUE_COLOR = '#39c2ee';
export const SECONDARY_LIGHT_BLUE_COLOR = '#d8ecf5';
export const SECONDARY_DARK_BLUE_COLOR = '#337AB7';
export const SECONDARY_PINK_COLOR = '#e7659b';
export const SECONDARY_LIGHT_PINK_COLOR = '#f1dddd';
export const HYPERLINK_COLOR = '#3AA3E3';
export const TABLE_BACKGROUND_ROW = '#F8F8F8';
export const HOVER_TABLE_BACKGROUND_ROW = 'rgba(85, 85, 85, 0.25)';
export const HOVER_OPTION_COLOR = '#D9EDF5';
export const INTERACTION_DATA_COLOR = '#7cb5ec';
export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};
export const CALENDAR_COLORS = [
  '#d15b8e',
  '#db5b5d',
  '#e4c433',
  '#53ac62',
  '#21aa95',
  '#4368a9',
  '#544693',
  '#784993',
];
export const CALENDAR_BUSY_COLOR = '#35a2c5';
export const RAKUNA_LOGO = 'https://s3.amazonaws.com/rakuna-app-org-logo-staging/organizations/logos/000/000/003/medium/rakuna_logo.jpg';
export const RAKUNA_LOGO_300X300_URL = 'https://static.rakuna.co/images/rakuna_logo_300x300.jpg';
export const PRIVACY_POLICY_LINK = 'https://www.rakuna.co/legal/privacy_policy.pdf';
export const GDPR_COMPLIANT_LINK = 'https://static.rakuna.co/files/Rakuna_GDPR_Privacy_Policy_Notice.pdf';
export const RAKUNA_WEBSITE = 'https://www.rakuna.co';
export const INTERVIEW_PUBLISHED_COLOR = '#39C2EE';
export const INTERVIEW_DRAFT_COLOR = '#CCCCCC';
export const INTERVIEW_SELECTED_COLOR = '#D8ECF5';
export const TABLE_ROW_CAPTION_COLOR = '#F0F0F0';
export const TERMS_OF_SERVICE_URL = 'https://www.rakuna.co/legal/rakuna_website_tos.pdf';
export const LIGHT_GREY_BACKGROUND = '#f8f8f8';
export const MEDIUM_GREY_BACKGROUND = '#f0f0f0';
export const MEDIUM_GREY = '#999999';
export const DARK_GREY_TEXT = '#555555';
export const LIGHT_GREY_TEXT = '#777777';
export const DISABLED_TEXT = '#555';
export const BLACK_TEXT = '#000000';
export const TROLLEY_GREY = '#808080';
export const FOREST_GREEN = '#228B22';
export const LIGHT_GREY = '#C4C4C4';
export const ORANGE_COLOR = '#ffa517';
export const VERY_LIGHT_GREY = '#FAFAFA';
export const LIGHT_BLACK = '#00000080';
export const CHIP_GREY = '#FAFAFA';
export const SAFETY_YELLOW = '#EED202';
export const WARNING_YELLOW = '#EF9E00';
export const ALERT_RED = '#DD1111';
export const DATE_TIME_FORMAT = 'yyyy/MM/dd hh:mm a';
export const FOOTER_COLOR = '#B5B5B5';
export const WHITE_COLOR = '#FFFFFF';
export const DATE_FORMAT_WITH_TZ = 'MMMM Do YYYY, hh:mm a Z';
export const DEFAULT_JS_DATE_FORMAT = 'YYYY-MM-DD hh:mm:ss Z';
export const FULL_WEEKDAY_DATE_TIME_FORMAT_WITH_TZ = 'ddd, MMM Do YYYY, hh:mma (z)';
export const LONG_DATE_TIME_FORMAT = 'MMM Do YY, h:mm a';
export const UTC_OFFSET_DATE_TIME_FORMAT = 'MMM Do YY, h:mm a (UTC Z)';
export const SHORT_DATE_TIME_FORMAT = 'h:mm a';
export const SHORT_DATE_TIME_FORMAT_WITH_TZ = 'h:mm a (UTC Z)';
export const PROFILE_DATETIME_FORMAT = 'ddd. MMMM DD, YYYY, hh:mm:ssa';
export const TOUCH_EMAIL_TIME_FORMAT = 'ddd. MMM DD, YYYY, hh:mma';
export const EMAIL_DECLINED_IMG = 'https://static.rakuna.co/images/interview-mailer/decline.png';
export const EMAIL_ACCEPTED_IMG = 'https://static.rakuna.co/images/interview-mailer/invitation.png';
export const REGISTRATION_THEME_COLORS = {
  '#00148c': 'Navy Blue',
  '#be2137': 'Post',
  '#ffa100': 'Sunrise',
  '#af9553': 'Sand',
  '#009a3a': 'Moss',
  '#0079f1': 'Sky',
  '#701f85': 'Violet',
  '#f16567': 'Rakuna Color',
};
export const CATEGORY_PRESET_COLORS = {
  '#00148c': 'Navy Blue',
  '#be2137': 'Post',
  '#af9553': 'Sand',
  '#009a3a': 'Moss',
  '#0079f1': 'Sky',
  '#701f85': 'Violet',
  '#f16567': 'Rakuna Color',
};
export const ICON_COLOR = '#555555';
export const LIGHT_WHITE = '#EEEEEE';
export const LIGHT_BLUE = '#5cc1f7';
export const SLATE_ICON_COLOR_ACTIVE = '#555555';
export const SLATE_ICON_COLOR_INACTIVE = '#CCCCCC';
export const SLATE_EDITOR_COLOR = 'rgba(0, 0, 0, 0.23)';
export const SLATE_EDITOR_COLOR_HOVER = '#0188EA';
export const BORDER_COLOR = '#CCCCCC';
export const LIGHT_BORDER_COLOR = '#DEDEDE';
export const PRIMARY_BLUE = '#1976D2';
export const CIRCULAR_PROGRESS_COLORS = {
  open: '#1976d2',
  click: '#199b49',
  opt_out: '#ff6d00',
  bounce: '#ff0000',
  reply: '#880880',
};
export const LINK_TO_DETAILS_COLOR = '#0094E7';
export const POST_EVENT_TEMPLATE_GUIDE_LINK = 'https://help.rakuna.co/vii-messaging/post-event-interview-email-template/create-a-post-event-email-template/';
export const IMPORT_PROSPECT_TEMPLATE_LINK = 'https://static.rakuna.co/files/sample_data/Rakuna-sample-import-prospects.csv';
export const HEAD_EMAIL_CAMPAIGN_COLOR = '#337AB7';
export const PROPSECT_IMPORT_LIMIT_FILE_SIZE = 100;
export const INITIAL_SLATE_VALUE = [{
  type: 'paragraph',
  children: [
    { text: '' },
  ],
}];
export const POSITIVE_INTEGER_REGEXP = /^[1-9]\d*$/;
export const HEX_COLOR_REGEX = /^#[0-9A-F]{6}$/i;
export const MAX_COLUMN_NAME_LENGTH = 30;
export const SYSTEM_EMAIL = 'system@rakuna.co';
export const INTERVIEW_NEXT_ACTION_COLORS = {
  waiting_action: '#555555',
  next_round: '#555555',
  hired: '#43A047',
  tentative: '#EF6C00',
  declined: '#DD1100',
};
export const BACKGROUND_IMAGES = {
  selfSchedule: {
    left: 'https://static.rakuna.co/images/web_apps/interview_scheduling/RedVector.svg',
    right: 'https://static.rakuna.co/images/web_apps/interview_scheduling/BlueVector.svg',
  },
};
export const RESUME = {
  fileSize: 1000 * 1000,
};
export const ERROR_MESSAGE_COLOR = '#D10000';
export const BACKDROP_COLOR = '#fff';
export const BUTTON_LINK_HOVER_COLOR = '#6dcbea';
export const NODES_DEPTH_LIMIT = 15;
export const VIRTUAL_EVENT_COLOR = '#68c5b5';
export const INITIAL_CATEGORY_VALUE = {
  name: '',
  color_code: Object.keys(CATEGORY_PRESET_COLORS)[0],
};
