import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import withStyles from '@mui/styles/withStyles';
import Parser from 'html-react-parser';
import { I18n } from 'i18n-js';
import SubmitButton from '../common/SubmitButton';
import translations from '../../locales.json';

const i18n = new I18n(translations);

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(5)}`,
    borderRadius: 0,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  forgotPwd: {
    marginTop: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
});

class SignInForm extends React.Component {
  state = {
    showPassword: false,
    formAttrs: {},
    childNodes: '',
    email: '',
    isShowEmailField: false,
  };

  componentDidMount = () => {
    const originalForm = document.getElementById('signin-form');
    if (!originalForm) { return; }
    const formAttrs = ['action', 'acceptCharset', 'method'].reduce((obj, val) => {
      const transformObj = obj;
      transformObj[val] = originalForm[val];
      return transformObj;
    }, {});
    const childNodes = originalForm.innerHTML;
    const { location: { search } } = this.props;
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    let { email } = JSON.parse(originalForm.getAttribute('recruiter'));
    email = email || (params.email || '');
    const isShowEmailField = !!email;
    this.setState({
      formAttrs,
      childNodes,
      email,
      isShowEmailField,
    });
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleChangeEmail = ({ target: { value } }) => {
    this.setState(() => ({ email: value }));
  };

  render() {
    const { classes } = this.props;
    const {
      showPassword, formAttrs, childNodes, email, isShowEmailField,
    } = this.state;
    const buttonText = isShowEmailField ? i18n.t('js.sign_in.form.button.sign_in') : i18n.t('js.sign_in.form.button.continue');
    if (!(isShowEmailField || /\/directing/.test(formAttrs.action))) {
      formAttrs.action += '/directing';
    }
    const renderPasswordField = (enableEmailField) => {
      if (!enableEmailField) { return null; }
      return (
        <React.Fragment>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">{i18n.t('js.sign_in.form.password.password')}</InputLabel>
            <Input
              name="recruiter[password]"
              type={showPassword ? 'text' : 'password'}
              id="recruiter_password"
              autoComplete="current-password"
              autoFocus={enableEmailField}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label={i18n.t('js.sign_in.form.password.label_password_button')}
                    onClick={this.handleClickShowPassword}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
            />
          </FormControl>
          <Typography variant="body1" className={classes.forgotPwd}>
            <Link component={RouterLink} to="/recruiter/password/new" color="inherit">
              {i18n.t('js.sign_in.form.password.forget')}
            </Link>
          </Typography>
        </React.Fragment>
      );
    };

    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4">
          {i18n.t('js.sign_in.form.button.sign_in')}
        </Typography>
        <form {...formAttrs} className={classes.form}>
          {Parser(childNodes)}
          <FormControl margin="normal" required={!isShowEmailField} fullWidth>
            <InputLabel htmlFor="email">{i18n.t('js.sign_in.form.email_address')}</InputLabel>
            <Input
              id="recruiter_email"
              name="recruiter[email]"
              autoComplete="email"
              value={email}
              autoFocus={!isShowEmailField}
              onChange={this.handleChangeEmail}
            />
          </FormControl>
          {renderPasswordField(isShowEmailField)}
          <SubmitButton text={buttonText} />
        </form>
      </Paper>
    );
  }
}

SignInForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SignInForm);
