import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Link from '@mui/material/Link';

const styles = theme => ({
  root: {
    width: 'auto',
    height: 'auto',
    background: '#fafafa',
    marginTop: theme.spacing(8),
    display: 'block',
    textAlign: 'center',
  },
  reference: {
    minWidth: 0,
    padding: 0,
    marginLeft: theme.spacing(2),
    '& a': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 14,
    },
  },
  copyRight: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginTop: theme.spacing(2),
    fontSize: 14,
  },
  callAction: {
    display: 'block',
    margin: 'auto',
    marginBottom: theme.spacing(9),
    maxWidth: 'max-content',
    minWidth: 'fit-content',
    padding: 0,
    '& p': {
      margin: 0,
      cursor: 'default',
    },
    '& a': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 14,
    },
  },
});

const linkActioncs = {
  signUp: {
    text: "Don't have an account? Sign Up",
    path: '/recruiter/sign_up',
  },
  signIn: {
    text: 'Already have an account? Sign In',
    path: '/recruiter/sign_in',
  },
};

const Footer = (props) => {
  const links = [
    { title: 'About', href: 'https://www.rakuna.co/about/' },
    { title: 'Blog', href: 'https://www.rakuna.co/blog/' },
    { title: 'Privacy Policy', href: 'https://www.rakuna.co/legal/privacy_policy.pdf' },
    { title: 'Terms of Service', href: 'https://app.rakuna.co/legal/Rakuna_Members_TOS.pdf' },
  ];
  const { classes, linkType } = props;
  const action = linkActioncs[linkType] || { text: '', path: '' };

  return (
    <React.Fragment>
      <BottomNavigation
        className={classes.root}
      >
        <BottomNavigationAction
          showLabel
          component={Link}
          href={action.path}
          label={action.text}
          className={classes.callAction}
        />
        {links.map(link => (
          <BottomNavigationAction
            showLabel
            component={Link}
            href={link.href}
            target="_blank"
            label={link.title}
            className={classes.reference}
            key={link.title}
          />
        ))}
        <BottomNavigationAction
          showLabel
          label={`© Copyright ${new Date().getFullYear()} Rakuna.co. All rights reserved.`}
          className={classes.callAction}
        />
      </BottomNavigation>
    </React.Fragment>
  );
};

Footer.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  linkType: PropTypes.string,
};

Footer.defaultProps = {
  linkType: '',
};

export default withStyles(styles)(Footer);
