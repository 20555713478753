import React from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import withStyles from '@mui/styles/withStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import SnackbarContainer from '../components/common/SnackbarContainer';
import PushFlashMessages from '../helpers/PushFlashMessages';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginTop: theme.spacing(11),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  toolbar: theme.mixins.toolbar,
});

const renderNestedComponent = (component, enqueueSnackbar, ...rest) => {
  const finalProps = Object.assign({ enqueueSnackbar }, ...rest);
  return React.createElement(component, finalProps);
};

const AuthenticationLayout = (props) => {
  const {
    classes, component, enqueueSnackbar, ...rest
  } = props;
  PushFlashMessages(enqueueSnackbar);
  return (
    <React.Fragment>
      <CssBaseline />
      <Header btnType="signUp" />
      <main className={classes.main}>
        <div className={classes.toolbar} />
        {renderNestedComponent(component, enqueueSnackbar, rest)}
      </main>
      <Footer linkType="signUp" />
    </React.Fragment>
  );
};

AuthenticationLayout.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  component: PropTypes.instanceOf(Object).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

const AuthenticationContainer = withStyles(styles)(withSnackbar(AuthenticationLayout));

const Authentication = props => (
  <SnackbarContainer>
    <AuthenticationContainer {...props} />
  </SnackbarContainer>
);

export default Authentication;
