import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import RakunaTheme from '../../components/common/RakunaTheme';
import SignIn from '../../containers/SignIn';

document.addEventListener('DOMContentLoaded', () => {
  render(
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={RakunaTheme}>
        <BrowserRouter>
          <SignIn />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>,
    document.querySelector('#root'),
  );
});
