import { createTheme } from '@mui/material/styles';
import 'typeface-open-sans';
import { DARK_GREY_TEXT, RAKUNA_COLOR } from '../../shared/Constants';

const RakunaTheme = createTheme({
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 15,
    body1: {
      fontWeight: 400,
    },
    h4: {
      fontWeight: 400,
    },
    h5: {
      fontWeight: 400,
    },
    h6: {
      fontWeight: 400,
    },
  },
  zIndex: {
    appBar: 1009,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: DARK_GREY_TEXT,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#fafafa',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: RAKUNA_COLOR,
          },
        },
      },
    },
  },
});

export default RakunaTheme;
