import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import Logo from '../../images/logo-rakuna.svg';

const styles = {
  root: {
    flexGrow: 1,
  },
  toolbar: {
    paddingLeft: 0,
  },
  grow: {
    flexGrow: 1,
  },
  mediaContainer: {
    flexGrow: 1,
  },
  logo: {
    width: 200,
    height: 50,
  },
  buttons: {
    marginLeft: 10,
    borderRadius: 0,
  },
};

const buttonActions = {
  signUp: {
    text: "Don't have an account?",
    btn: 'Sign Up',
    path: '/recruiter/sign_up',
  },
  signIn: {
    text: 'Already have an account?',
    btn: 'Sign In',
    path: '/recruiter/sign_in',
  },
};

const renderBtn = (action, style) => {
  if (!action) return null;
  return (
    <React.Fragment>
      <Hidden lgDown>
        <Typography variant="body1" color="inherit">
          {action.text}
        </Typography>
      </Hidden>
      <Button
        variant="contained"
        color="inherit"
        className={style.buttons}
        href={action.path}
        data-method="delete"
      >
        {action.btn}
      </Button>
    </React.Fragment>
  );
};

const Header = (props) => {
  const { classes, btnType } = props;
  const action = buttonActions[btnType];

  return (
    <div className={classes.root}>
      <AppBar elevation={1} position="fixed" color="default">
        <Toolbar className={classes.toolbar}>
          <div className={classes.mediaContainer}>
            <Logo className={classes.logo} />
          </div>
          {renderBtn(action, classes)}
        </Toolbar>
      </AppBar>
    </div>
  );
};

Header.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  btnType: PropTypes.string,
};

Header.defaultProps = {
  btnType: '',
};

export default withStyles(styles)(Header);
