import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import { RAKUNA_COLOR } from '../../shared/Constants';

const styles = theme => ({
  submit: {
    backgroundColor: RAKUNA_COLOR,
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('xl')]: {
      marginTop: theme.spacing(3),
    },
    borderRadius: 0,
    '&:hover': {
      backgroundColor: RAKUNA_COLOR,
    },
  },
});

const SubmitButton = ({
  classes, isValid, text, ...rest
}) => (
  <Button
    {...rest}
    type="submit"
    fullWidth
    variant="contained"
    color="secondary"
    className={classes.submit}
    disabled={!isValid}
  >
    {text}
  </Button>
);

SubmitButton.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  isValid: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

SubmitButton.defaultProps = {
  isValid: true,
};

export default withStyles(styles)(SubmitButton);
