import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Parser from 'html-react-parser';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SubmitButton from '../common/SubmitButton';
import PushSnackbarMessage from '../../helpers/PushSnackbarMessage';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
});

class SetNewPasswordForm extends React.Component {
  state = {
    showPassword: false,
    formAttrs: {},
    childNodes: '',
  };

  componentDidMount = () => {
    const originalForm = document.getElementById('set-new-password-form');
    if (!originalForm) { return; }
    const formAttrs = ['action', 'acceptCharset', 'method'].reduce((obj, val) => {
      const transformObj = obj;
      transformObj[val] = originalForm[val];
      return transformObj;
    }, {});
    const childNodes = originalForm.innerHTML;
    this.setState({
      formAttrs,
      childNodes,
    });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    const {
      values: { password, passwordConfirmation, resetPasswordToken },
      errors,
      touched,
      handleChange,
      isValid,
      setFieldTouched,
      classes,
      enqueueSnackbar,
    } = this.props;

    const change = (e, name) => {
      e.persist();
      handleChange(e);
      setFieldTouched(name, true, false);
    };

    const {
      showPassword, formAttrs, childNodes,
    } = this.state;

    const hiddenFields = [
      { name: 'recruiter[password]', value: password },
      { name: 'recruiter[password_confirmation]', value: passwordConfirmation },
    ];

    const showErrorOnSnackbar = () => {
      Object.values(errors).map(error => PushSnackbarMessage(enqueueSnackbar, error, 'warning'));
    };

    return (
      <form {...formAttrs} className={classes.form}>
        {Parser(childNodes)}
        {hiddenFields.map(field => (
          <input
            type="hidden"
            key={field.name}
            name={field.name}
            value={field.value}
          />
        ))}
        <FormControl margin="normal" required fullWidth>
          <input
            type="hidden"
            id="recruiter_reset_password_token"
            name="recruiter[reset_password_token]"
            value={resetPasswordToken}
          />
          <InputLabel htmlFor="password">New Password</InputLabel>
          <Input
            id="password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            error={touched.password && Boolean(errors.password)}
            onChange={e => change(e, 'password')}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  tabIndex="-1"
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
              )}
          />
          <FormHelperText>{touched.password ? errors.password : ''}</FormHelperText>
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">New Password Confirmation</InputLabel>
          <Input
            id="passwordConfirmation"
            type={showPassword ? 'text' : 'password'}
            value={passwordConfirmation}
            error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
            onChange={e => change(e, 'passwordConfirmation')}
            onBlur={showErrorOnSnackbar}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  tabIndex="-1"
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
              )}
          />
          <FormHelperText>{touched.passwordConfirmation ? errors.passwordConfirmation : ''}</FormHelperText>
        </FormControl>
        <SubmitButton isValid={isValid} text="Change my password" />
      </form>
    );
  }
}

SetNewPasswordForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(SetNewPasswordForm);
