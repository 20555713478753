import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Parser from 'html-react-parser';
import { EMAIL_REGXP } from '../../shared/Constants';
import SubmitButton from '../common/SubmitButton';
import PushSnackbarMessage from '../../helpers/PushSnackbarMessage';

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(5)}`,
    borderRadius: 0,
  },
  signInLink: {
    marginTop: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
});

class ResetPasswordForm extends React.Component {
  state = {
    valid: false,
    formAttrs: {},
    childNodes: '',
    email: '',
  };

  handleInputChange = (e) => {
    const { target } = e;
    const { value } = target;
    const emailRegexp = new RegExp(EMAIL_REGXP);
    this.setState({ email: value, valid: emailRegexp.test(value) });
  }

  componentDidMount = () => {
    const originalForm = document.getElementById('reset-password-form');
    if (!originalForm) { return; }
    const formAttrs = ['action', 'acceptCharset', 'method'].reduce((obj, val) => {
      const transformObj = obj;
      transformObj[val] = originalForm[val];
      return transformObj;
    }, {});
    const childNodes = originalForm.innerHTML;
    this.setState({
      formAttrs,
      childNodes,
    });
  };

  render() {
    const { classes, enqueueSnackbar } = this.props;
    const {
      valid, formAttrs, childNodes, email,
    } = this.state;
    const showErrorOnSnackbar = () => {
      if (email && !valid) {
        PushSnackbarMessage(enqueueSnackbar, 'Invalid email address', 'warning');
      }
    };

    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4">
          Reset Password
        </Typography>
        <form {...formAttrs} className={classes.form}>
          {Parser(childNodes)}
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              id="recruiter_email"
              name="recruiter[email]"
              autoComplete="email"
              onChange={this.handleInputChange}
              onBlur={showErrorOnSnackbar}
              value={email}
              autoFocus
            />
          </FormControl>
          <Typography variant="body1" className={classes.signInLink}>
            <Link component={RouterLink} to="/recruiter/sign_in" color="inherit">
              Return to log in
            </Link>
          </Typography>
          <SubmitButton isValid={valid} text="Send reset password link" />
        </form>
      </Paper>
    );
  }
}

ResetPasswordForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(ResetPasswordForm);
