import React from 'react';
import { Switch, Route } from 'react-router';
import Authentication from './Authentication';
import SignInForm from '../components/sign_in/SignInForm';
import ResetPasswordForm from '../components/reset_password/ResetPasswordForm';
import SetNewPassword from '../components/reset_password/SetNewPassword';

const SignIn = () => (
  <Switch>
    <Route
      path="/recruiter/password/new"
      render={props => <Authentication {...props} component={ResetPasswordForm} />}
    />
    <Route
      path="/recruiter/password/edit"
      render={props => <Authentication {...props} component={SetNewPassword} />}
    />
    <Route
      path="/recruiter/sign_in"
      render={props => <Authentication {...props} component={SignInForm} />}
    />
    <Route
      path="/"
      render={props => <Authentication {...props} component={SignInForm} />}
    />
  </Switch>
);

export default SignIn;
