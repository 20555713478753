import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Formik } from 'formik';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import * as Yup from 'yup';
import SetNewPasswordForm from './SetNewPasswordForm';

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(5)}`,
    borderRadius: 0,
  },
});

const validationSchema = Yup.object({
  password: Yup.string().required('Enter your password')
    .min(8, 'Password is too short - should be 8 letters minimum.')
    .matches(/[a-z]/, 'Password has to contain lowercase letter.')
    .matches(/[A-Z]/, 'Password has to contain uppercase letter.')
    .matches(/[0-9]/, 'Password has to contain number '),
  passwordConfirmation: Yup.string().required('Confirm your password')
    .oneOf([Yup.ref('password')], 'Password Confirmation does not match Password'),
});

const SetNewPassword = ({ classes, location, enqueueSnackbar }) => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const values = {
    password: '',
    passwordConfirmation: '',
    resetPasswordToken: params.reset_password_token,
  };

  return (
    <Paper className={classes.paper}>
      <Typography component="h1" variant="h4">
        Password Reset
      </Typography>
      <Formik
        render={formikProps => (
          <SetNewPasswordForm
            enqueueSnackbar={enqueueSnackbar}
            {...formikProps}
          />
        )}
        initialValues={values}
        validationSchema={validationSchema}
      />
    </Paper>
  );
};

SetNewPassword.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(SetNewPassword);
